.root {
  margin-bottom: 40px;
}

.buttonAdd {
  padding: 0 24px;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: var(--matterColor);
  border: none;
  cursor: pointer;

  @media(--viewportMedium){
    padding: 0;
  }
}

.datesInputContainer {
  position: relative;
}

.removeButton {
  position: absolute;
  top: 24px;
  right: 16px;
  z-index: 1;
  font-size: 20px;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }

  @media(--viewportMedium){
    right: 0;
  }
}
