.root {
    margin-right: 3px;
    margin-bottom: 3px;
  & path {
    fill: var(--matterColor)
    
  }

  &:hover {
    & path {
        fill: var(--marketplaceColor)
        
      }
  }
}
