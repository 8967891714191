.strokeMarketplaceColor {
  stroke: var(--matterColor);
}

.fillSuccessColor {
  fill: var(--matterColor);
}

.strokeLightColor {
  stroke: var(--matterColorLight);
}
