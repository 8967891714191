@import '../../styles/propertySets.css';

:root {
  --imageWidthDesktop: 248px;
  --checkboxWidthDesktop: 42px;
  --imageWidthTablet: 200px;
  --imageWidthMobile: 100%;
}

.root {
  margin-bottom: 20px;
  opacity: 0.5;
}

.checked {
  opacity: 1;
}

.checkbox{
  width: 100%;
  padding: 13px 0 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover .checkboxRoot {
    border: 2px solid var(--failColor);
  }

  @media(--viewportMedium){
    width: var(--checkboxWidthDesktop);
  }
}

.checkboxRoot {
  width: 14px;
  height: 14px;
  border: 2px solid var(--matterColorAnti);
  border-radius: 1.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 1px;

  & > svg {
    display: none;
  }
}

.checkboxChecked {
  background: var(--failColor);
  border: 2px solid var(--failColor);

  & > svg {
    display: block;
  }
} 


.container {
  background: var(--matterColorLight);
  border: 1px solid var(--matterColorAnti);
  border-radius: 2px;
  /* height: 195px; */

  @media(--viewportMedium){
    display: flex;
  }
}

.imageWrapper {
  width: var(--imageWidthMobile);
  height: 160px;
  position: relative;

  @media(--viewportMedium){
    height: auto;
    width: var(--imageWidthTablet);
    padding: 12px 0 12px;
  }

  @media(--viewportMLarge){
    width: var(--imageWidthDesktop);
    padding: 13px 0 16px;
  }
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 100%;
}

.imageInner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cardContent {
  width: 100%;
  padding: 20px 20px 20px 40px;

  @media(--viewportMedium){
    width: calc(100% - var(--imageWidthTablet) - var(--checkboxWidthDesktop));
  }

  @media(--viewportMLarge){
    width: calc(100% - var(--imageWidthDesktop) - var(--checkboxWidthDesktop));
    padding: 30px 20px 20px 60px;
  }
}

.cardContentTop {
  padding-bottom: 10px;

  @media(--viewportMLarge){
    display: flex;
    justify-content: space-between;
  
    & > * {
      width: 33.33333333%;
    }
  }
}

.additioanalInfo {
  padding-bottom: 30px;
}

.cardContentBottom {
  @media(--viewportMedium){
    display: flex;
  }
}

.listingInfo {
  padding-right: 20px;
}

.bookingDates {
  @media(--viewportMLarge) {
    width: 40%;
  }
}

.bookingPrice {
  padding-left: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}


.title,
.bookingPrice {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.3px;
  color: var(--matterColor);
  margin: 0;
}

.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 10px;

  @media(--viewportMLarge){
    margin-bottom: 0;
  }
}

.textRight {
  @media(--viewportMLarge){
    text-align: right;
  }
}

.textCenter {
  @media(--viewportMLarge){
    text-align: center;
  }
}

.textBold {
  color: var(--matterColorDark);
  font-weight: 700;
}

.controlButton {
  background: var(--matterColorLight);
  border: 1px solid var(--customGrayBorderLight);
  border-radius: 2px;
  font-size: 14px;
  line-height: 24px;
  padding: 7.5px 20px;
  cursor: pointer;
  margin-bottom: 20px;
  width: 100%;
  text-align: center;

  & span {
    margin-left: 6px;
  }

  &:last-child {
    margin-right: 0;
  }

  @media(--viewportMedium){
    margin-right: 24px;
    margin-bottom: 0;
    width: auto;
  }
}

a.controlButton {
  display: inline-block;
  color: var(--matterColor);
  padding: 7.5px 13.5px;

  &:hover {
    text-decoration: none;
  }
}

.sectionAvatar {
  position: absolute;
  right: 6px;
  bottom: 6px;
  

  @media(--viewportMedium){
    right: 0;
    transform: translateX(50%);
  }
}

.avatar {

}

.unavailable {
  color: var(--marketplaceColor);
  font-weight: 600;
}

.timeZoneTip {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
}