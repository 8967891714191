.root {
}

.bookingInfo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateSection {
  margin-right: 5px;
  line-height: 20px;
  /*font-size: 20px;*/
}

.timeZoneTip {
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.2;
}

.adminModal {
  font-size: 20px;
  margin-bottom: 20px;
}

.adminModalTimeZoneTip {
  font-size: 18px;
}
