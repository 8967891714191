@import '../../styles/propertySets.css';

.root {
  position: relative;
  display: inline-block;
}

.label {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceSearchFilterLabelFontStyles;

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorAnti);

  &:focus {
    outline: none;
    text-decoration: none;
  }
}

.labelSelected {
  @apply --marketplaceButtonStyles;
  @apply --marketplaceSearchFilterLabelFontStyles;
  font-weight: var(--fontWeightSemiBold);

  padding: var(--marketplaceButtonSmallDesktopPadding);
  width: auto;
  height: auto;
  min-height: 0;
  border-radius: 4px;
  border: 1px solid var(--matterColorAnti);

  &:hover,
  &:focus {
    border: 1px solid var(--matterColorAnti);
  }
}

/* .label.isOpen, 
.labelSelected.isOpen {
  background: transparent;
  border-color: transparent;
  color: var(--matterColor);
  font-weight: var(--fontWeightRegular);
} */
