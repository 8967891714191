.root {

}

.uploadLabel {

}

.uploadInput {
  display: none;
}
