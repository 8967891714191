@import '../../styles/propertySets.css';

.root {
  position: relative;
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: var(--matterColorNegative);

  width: 100%;
  padding: 24px 24px 75px 24px;
  background-color: var(--matterColorBright);

  @media (--viewportMedium) {
    padding: 0 24px 75px 24px;
  }

  @media (--viewportLarge) {
    padding: 0 0 121px 0;
    border-top: none;
  }
}

/* Wrapper to limit the top border in desktop
 * view from the outermost paddings*/
.topBorderWrapper {
  @media (--viewportMedium) {
    padding-top: 41px;
  }

  @media (--viewportLarge) {
    padding-top: 79px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--matterColorNegative);
  }
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (--viewportMedium) {
    margin: 0 auto;
  }

  @media (--viewportLarge) {
    max-width: 1168px;
    padding: 0 36px;
  }

  @media (--viewportXLarge) {
    padding: 0;
  }
}

/* Mobile only social media links */
.someLiksMobile {
  margin-bottom: 24px;
  text-align: center;

  @media (--viewportMedium) {
    display: none;
  }
}

.icon {
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 12px;
  text-align: center;
}

/* Footer links */
.links {
  /* Layout */
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  
  @media (--viewportMedium) {
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}

/* Desktop: logo and organization stuff */
.organization {
  flex-shrink: 0;
  flex-basis: 140px;
  display: none;
  margin-right: 24px;
  margin-bottom: 24px;
  
  @media (--viewportMedium) {
    display: block;
    order: 1;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    flex-basis: 150px;
    margin-right: 57px;
  }
  @media (--viewportLargeWithPaddings) {
    margin-right: 12px;
  }
}

.logoLink {
  display: block;
  margin-bottom: 5px;
}

.logo {
  display: flex;
  align-items: center;
  object-fit: contain;
  object-position: left center;
  width: 100%;
  height: 26px;
  max-width: 130px;

  & img {
    width: 100%;
  }
}

.organizationInfo {
}

.organizationDescription,
.organizationCopyright {
  @apply --marketplaceTinyFontStyles;
}

.organizationCopyright {
  margin: 0;
  
  @media (--viewportLarge) {
    text-align: center;
  }
}

.copyrightLink {
  color: var(--customLightGray);
  transition: var(--transitionStyleButton);
  letter-spacing: -0.1px;


  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

/* Desktop and Mobile: links to more information */
.infoLinks {
  order: 2;
  flex-basis: 100%;
  margin-right: 24px;
  margin-bottom: 24px;
  
  @media (--viewportSmall) {
    flex-basis: 210px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

.list {
  margin-top: 0;
  margin-bottom: 0;
}

.listItem {
  min-height: 24px;
  margin-bottom: 6px;
  text-align: center;

  @media (--viewportMedium) {
    margin-bottom: 0;
    text-align: left;
  }
}

.link {
  /* Font */
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightRegular);
  line-height: 24px;
  color: var(--matterColor);
  transition: var(--transitionStyleButton);

  margin-top: 0;
  margin-bottom: 12px;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/* Desktop and Mobile: searches - first column */
.searches {
  order: 3;
  flex-basis: 100%;
  margin-right: 24px;
  margin-bottom: 24px;
  
  @media (--viewportMedium) {
    flex-basis: 165px;
  }

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
  }
}

/* Desktop and Mobile: searches - second column */
/* This is not always visible */
.searchesExtra {
  order: 4;
  flex-basis: 100%;
  margin-right: 24px;
  margin-bottom: 24px;

  @media (--viewportMedium) {
    padding-top: 2px;
    margin-right: 12px;
    flex-basis: 165px;
  }
}

/* Desktop: extra links (social media links and legal links) */
.extraLinks {
  flex-shrink: 0;
  order: 5;
  display: none;
  flex-basis: 240px;
  margin-right: 24px;
  /* margin-bottom: 24px; */

  @media (--viewportMedium) {
    display: flex;
    flex-direction: column;
    margin-right: 12px;
  }
  @media (--viewportLarge) {
    margin-right: 0;
  }
}

.someLinks {
  display: flex;
  flex-direction: column;
  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: space-between;
  }
}


.legalMatters {
  /* Get extra space from parent element (which is using flexbox) */
  flex-grow: 1;

  /* Layout */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 44px;
}

.tosAndPrivacy {
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    margin-bottom: 10px;
  }
}

.legalLink,
.privacy,
.terms {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--customLightGray);
  transition: var(--transitionStyleButton);
  
  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
    margin-bottom: 0;
    line-height: 24px;
  }
}

/* Mobile: copyright mark and legal links for mobile */
.copyrightAndTermsMobile {
  /* Positioning */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  /* Dimensions */
  height: 60px;
  padding: 0 24px 18px 24px;

  /* Layout */
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  background-color: var(--matterColorLight);

  @media (--viewportMedium) {
    display: none;
  }
}

.organizationCopyrightMobile {
  /* Font */
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  transition: var(--transitionStyleButton);

  /* Tell parent component how much space this one prefers */
  flex-basis: 172px;

  margin-top: 0;
  margin-bottom: 0;

  &:hover {
    color: var(--marketplaceColor);
    text-decoration: none;
  }
}

.tosAndPrivacyMobile {
  flex-basis: 172px;
  text-align: right;
}

.privacy {
  margin-right: 24px;
}
