.root {
}

.input {
  border-bottom-color: var(--attentionColor);
  &::placeholder {
    color: #929AA0;
  }

  & input {
    &::placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }
    &::-moz-placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }
  }
}

.inputSuccess {
  border-bottom-color: var(--matterColor);

  & label {
    color: var(--matterColor);
  }
}

.inputError {
  border-bottom-color: var(--failColor);

  & label {
    color: var(--matterColor);
  }
}

.textarea {

  &::placeholder {
    color: #929AA0;
  }

  & input {

    &::placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }

    &::-moz-placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }
  }
  & textarea {
    padding: 4px 0 0;
    &::placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }

    &::-moz-placeholder {
      color: var(--matterColorAnti);
      opacity: 1;
    }
  }
}
