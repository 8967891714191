@import '../../styles/propertySets.css';

.root {
  /* Adding empty .root class to enforce className overwrite */
}

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
  font-size: 30px;
  letter-spacing: -0.9px;
  text-transform: inherit;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
  font-size: 20px;
  line-height: 32px;
  font-weight: var(--fontWeightRegular);
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

.helperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
  font-weight: var(--fontWeightRegular);
}

.helperText {
  @apply --marketplaceModalHelperText;
  font-weight: var(--fontWeightRegular);
  margin-bottom: 10px;
}

.error {
  @apply --marketplaceModalErrorStyles;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
  margin-top: 28px;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;
}

.reminderModalLinkButton {
  @apply --marketplaceButtonStylesPrimary;
}
