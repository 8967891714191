@import '../../styles/propertySets.css';

.title {
  @apply --marketplaceH1FontStyles;
  
  margin-top: 0;
  margin-bottom: 2px;
}

.subTitle {
  @apply --marketplaceH3CustomFontStyles;
  margin: 0 0 20px;
  letter-spacing: normal;
}

.locations {
  display: flex;
  flex-direction: column;
  margin-top: 0px;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.location {
  width: 100%;
  margin-top: 25px;
  margin-bottom: 14px;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
  }

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

.location {
  position: relative;

  @media (--viewportMedium) {
    margin-right: 40px;
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: var(--boxShadowSectionLocationHover);
  }
}

.location:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

/* A wrapper for a responsive image that holds it's aspect ratio */
.imageWrapper {
  position: relative;
  width: 100%;
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

/* An image wrapper that defines the images aspect ratio */
.aspectWrapper {
  padding-bottom: calc(6 / 13 * 100%); /* 13:6 Aspect Ratio */

  @media (--viewportMedium) {
    padding-bottom: 224px;
  }
}

.locationImage {
  /* Layout - image will take space defined by aspect ratio wrapper */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;
}

.linkText {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  @apply --marketplaceH2CustomFontStyles;
  margin: 0;
  text-align: center;
  
}

.locationName {
  color: var(--matterColorLight);
}

.button {
  display: block;
  @apply --marketplaceH3CustomFontStyles;
  color: var(--matterColor);
  text-decoration: underline;
  font-weight: var(--fontWeightRegular);
  margin: 20px 0 34px;
  
  @media (--viewportMedium) {
    margin: 0 0 56px;
  }
}

.divider {
  width: 192px;
  height: 8px;
  background: var(--marketplaceColor);
}
