@import '../../styles/propertySets.css';

.root {
  margin-bottom: 40px;
  
  @media (--viewportMedium) {
    margin-bottom: 95px;
  }
}

.title {
  @apply --marketplaceH1FontStyles;
  margin-top: 0;
  margin-bottom: 34px;
  
  @media (--viewportMedium) {
    margin-bottom: 56px;
  }
}

.steps {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (--viewportMedium) {
    flex-direction: row;
  }
}

.step {
  position: relative;
  margin-bottom: 20px;

  @media (--viewportMedium) {
    width: 395px;
    margin-right: 40px;
    padding-left: 55px;

  }
}

.step:last-of-type {
  @media (--viewportMedium) {
    margin-right: 0;
  }
}

.stepNumber {
  display: block;
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 50%;
  border: 1px solid;
  text-align: center;
  margin: 0 0 20px 0;
  font-size: 18px;
  font-weight: var(--fontWeightRegular);
  font-weight: 700;
  
  @media (--viewportSmall) {
    display: inline-block;
    margin: 0 20px 0 0;
  }

  @media (--viewportMLarge) {
    position: absolute;
    left: 0px;
    top: -5px;
    margin: 0;
  }
}

.stepTitle {
  margin: 0 0 15px;
}

.stepDescription {
  margin: 0;
  font-size: 14px;
  
  @media (--viewportMedium) {
    font-size: 20px;
  }
}

.stepsText {
  width: 100%;
}

.stepsImg {
  text-align: center;
  
  @media (--viewportLarge) {
    flex-shrink: 0;
  }

  & img {
    width: 100%;
    max-width: 100%;
  }
}


.button {
  @apply --marketplaceButtonStyles;
  max-width: 327px;
  margin-bottom: 20px;
}

.roleTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 82px;
}

.role {
  flex-grow: 1;
  width: 50%;
  border-bottom: 1px solid var(--customGrayBorder);
  padding-bottom: 10px;
  color: var(--matterColor);
  text-align: center;

}

.activeRole {
  font-weight: 700;
  font-size: 24px;
  border-bottom: 3px solid var(--matterColor);
}

.stepSubTitle {
  font-weight: 700;
  font-size: 21px;
  color: var(--matterColor);
  margin-bottom: 45px;
  margin-top: 0;
}

.preStep {
  font-size: 20px;
  font-weight: 400;
  color: var(--matterColor);
  max-width: 528px;
  margin-bottom: 45px;
  margin-top: 0;
}