@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.bookingDates {
  flex-shrink: 0;
  margin-bottom: 38px;

  /* Ensure that calendar dropdown gets some stacking context relative to other form items below */
  z-index: 1;
}

.priceBreakdownContainer {
  padding: 0 24px;
  margin-bottom: 40px;

  @media (--viewportMedium) {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1px;
  }
}

.priceBreakdownTitle {
  /* Font */
  color: var(--matterColorAnti);

  margin-top: 5px;
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 5px;
    margin-bottom: 26px;
  }
}

.receipt {
  flex-shrink: 0;
  margin: 0 0 24px 0;
}

.error {
  color: var(--failColor);
  margin: 0 24px;
  display: inline-block;
}

.spinner {
  margin: auto;
}

.sideBarError {
  @apply --marketplaceH4FontStyles;
  color: var(--failColor);
  margin: 0 24px 12px 24px;

  @media (--viewportMedium) {
    margin: 0 0 12px 0;
  }
}

.smallPrint {
  @apply --marketplaceTinyFontStyles;
  color: var(--matterColorAnti);
  text-align: center;
  margin: auto 24px 20px 24px;
  flex-shrink: 0;

  @media (--viewportMedium) {
    margin-top: auto;
    margin-bottom: 20px;
  }

  @media (--viewportLarge) {
    margin-top: 4px;
    margin-bottom: 21px;
  }
}

.submitButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
  }
}

.bookNowButtonWrapper {
  flex-shrink: 0;
  padding: 0 24px 24px 24px;
  width: 100%;

  @media (--viewportMedium) {
    padding: 0;
    padding-bottom: 24px;
  }
}

.unavailableDates {
  padding-bottom: 16px;
}

.unavailableDatesTitle {
  color: var(--marketplaceColor)
}

.unuavailableItem {
  margin-bottom: 16px;
  padding-right: 20px;
  position: relative;
}

.unuavailableItemDate {

}

.unuavailableItemDay {

}

.unuavailableItemTime {

}

.unavailableDatesRemove {
  position: absolute;
  top: 6px;
  right: 4px;
  z-index: 1;
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;

  &:hover {
    text-decoration: none;
  }
}

.fieldItem {
  margin-top: 24px;
  padding: 0 24px;

  @media (--viewportMedium) {
    padding: 0;
  }
  
  & input {
    font-size: 20px;
    font-weight: var(--fontWeightRegular);
    padding-left: 0;
  }

  & .locationAutocompleteInputIcon {
    display: none;
  }

  & .locationAutocompleteInput {
    padding-left: 0;
    height: 41px;
    border-bottom-color: var(--matterColor);
  }
}

.fieldItemFirst {
  margin-top: 0;
}
