@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';
.root {
  @apply --marketplaceModalFormRootStyles;
  height: auto;
  justify-content: flex-start;
  color: var(--matterColorDarkCustom);

  @media (--viewportMedium) {
    justify-content: space-between;
  }

  & input {
    background: var(--matterColorLight);
    border: none;
    border-radius: var(--borderRadiusMedium);
    font-size: 18px;
    letter-spacing: 0.72px;

    &::placeholder {
      letter-spacing: 0.72px;
    }
  }

  & label {
    margin-bottom: 6px;
    padding: 0;
    text-transform: uppercase;
    letter-spacing: 2.2px;
    color: var(--matterColorDark);
    font-size: 22px;
    font-weight: var(--fontWeightRegular);
  }
}

.title {
  @apply --marketplaceModalTitleStyles;
  margin: 40px 0;
  
  @media (--viewportMedium) {
    margin: 0 0 40px 0;
  }
}

.error {
  color: var(--failColor);
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 500;
}

.submitButton {
  margin-top: 40px;
}

.input {
  width: 100%;
}

.customErrorInputStyle {

  & input {
    /* border: 1px solid var(--borderColor); */
  }

  & > div {
    display: none;  
  }
}

.resendMessage {
  margin-bottom: 20px;
  font-weight: 400;

  & a {
    font-weight: 600;
  }
}