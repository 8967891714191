@import '../../styles/propertySets.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.lineItem {
  @apply --marketplaceH4FontStyles;
  margin: 0;

  @media (--viewportMedium) {
    padding-bottom: 2px;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bookingPeriod {
  flex: 1 1;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-bottom: 6px;

  @media (--viewportMedium) {
    padding-bottom: 8px;
  }
}

.bookingPeriodSectionRigth {
  text-align: right;
}

.dayLabel {
  @apply --marketplaceH5FontStyles;
  margin: 0;
  color: var(--matterColorAnti);
  line-height: 24px;
  padding-top: 2px;
  padding-bottom: 4px;

  @media (--viewportMedium) {
    padding-top: 1px;
    padding-bottom: 7px;
  }
}

.dayInfoHolder {
  display: flex;
  justify-content: space-between;
}

.dayInfo {
  @apply --marketplaceButtonFontStyles;
  font-weight: var(--fontWeightBold);
  font-size: 14px;
  line-height: 24px;

  width: calc(50% - 10px);

  & label {
    font-size: 14px;
    font-weight: var(--fontWeightRegular);
    padding: 0;
  }
}

.subTotalLineItem {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightBold);
  margin: 0;
  padding: 5px 0 1px 0;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & .itemLabel,
  & .itemValue {
    font-weight: var(--fontWeightBold);
    width: calc(50% - 10px);
  }
}

.lineItemAdditionalInfo {
  margin-bottom: 14px;

  & .itemLabel {
    font-size: 14px;
    line-height: 24px;
    font-weight: var(--fontWeightBold);
  }
}

.lineItemTotal {
  @apply --marketplaceH4FontStyles;
  margin: 0;
  padding: 2px 0 0;
  /* padding-top: 6px;
  padding-bottom: 6px; */

  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;

  /* @media (--viewportMedium) {
    padding-bottom: 2px;
    padding-top: 12px;
  } */


  & > div {
    width: calc(50% - 10px);
  }
}

.bookingDates {
  width: 100%;
}

.priceBreakdownTitle {
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.125px;
  color: var(--matterColorAnti);
  /* margin-bottom: 11px; */
  margin: 0 0 11px;
}

.itemLabel {
  @apply --marketplaceSmallFontStyles;
  flex: 1;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--matterColor);
}
.itemLabelDate {
  display: flex;
  justify-content: flex-end;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
}

.divider {
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.09px;
  color: #000;
  margin-left: 32px;
}

.itemValue {
  @apply --marketplaceSmallFontStyles;
  margin: 0 0 0 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.totalDivider {
  /* dimensions */
  width: 100%;
  height: 1px;
  margin: 12px 0 5px 0;

  border: none;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    margin: 4px 0;
  }
}

.totalLabel {
  @apply --marketplaceSmallFontStyles;
  padding: 0;
  font-size: 14px;
  line-height: 24px;
  font-weight: 700;
  color: var(--matterColor);
}

.totalPrice {
  @apply --marketplaceButtonFontStyles;
  margin: 0;
  padding-top: 0px;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  letter-spacing: -0.3px;
}

.feeInfo {
  @apply --marketplaceTinyFontStyles;
  flex-shrink: 0;
  margin: 0;
  color: var(--matterColorAnti);
  padding-top: 4px;
  padding-bottom: 14px;

  @media (--viewportMedium) {
    padding-top: 11px;
    padding-bottom: 21px;
  }
}

.yellowLine {
  background-color: var(--marketplaceColor);
}

.timeZoneTip {
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
}