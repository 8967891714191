@import '../../styles/propertySets.css';

.root {

  & p {
    /* @apply --marketplaceH4FontStyles; */
    margin: 0 0 30px 0;
    font-size: 16px;
    line-height: 24px;
  }
  & h3 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 24px;

  }

  & h2 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 29px 0 13px 0;

    @media (--viewportMedium) {
      margin: 32px 0 0 0;
    }
  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
  }

  & ul {
    list-style: disc;
    padding-left: 20px;
    margin: 0 0 20px 0;
  }

  & ul li {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.11px;
  }

  & .textBig {
    font-size: 20px;
    line-height: 32px;
    letter-spacing: -0.11px;
    font-weight: var(--fontWeightBold);
  }
}

