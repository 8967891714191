@import '../../styles/propertySets.css';

.root {

  & p {
    /* @apply --marketplaceH4FontStyles; */
    margin: 0 0 30px 0;
    font-size: 16px;
    line-height: 24px;
  }
  & h3 {
    /* Adjust heading margins to work with the reduced body font size */
    margin: 0 0 20px 0;
    font-size: 16px;
    line-height: 24px;

  }

  & .lastUpdated {
    @apply --marketplaceBodyFontStyles;
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 20px;
  }
}
