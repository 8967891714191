@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

/* 404 page */

.root {
  /* Expand to the available space */
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  max-width: 587px;
  width: 80%;
  margin-top: 60px;
  margin-bottom: 168px;

  @media (--viewportMedium) {
    margin-top: 10vh;
  }
}

.number {
  @apply --marketplaceHeroTitleFontStyles;
  font-size: 60px;
  text-align: center;
  color: var(--marketplaceColor);
  margin: 0 0 12px;
  letter-spacing: -2px;
  
  @media (--viewportMedium) {
    font-size: 90px;
  }
}

.heading {
  @apply --marketplaceModalTitleStyles;

  text-align: center;
  margin: 0 0 23px;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: -0.9px;
}

.description {
  text-align: center;
  margin: 0 0 29px;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.11px;
}

.searchForm {
  max-width: 408px;
  margin: 0 auto;

  background-color: var(--matterColorLight);
  border-bottom: 4px solid var(--marketplaceColor);
  box-shadow: var(--boxShadowNotFoundPageSearch);

  @media (--viewportMedium) {
    margin-top: 64px;
  }

  & div > div,
  & input {
    border-bottom: none;
  }

  & input {
    font-size: 18px;
  }
}
