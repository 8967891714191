@import '../../styles/propertySets.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  display: block;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  
  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);
  
  /* Layout */
  padding: 15px 30px 20px 30px;
  margin-top: 7px;
  background-color: var(--matterColorLight);
  width: 328px;

  /* Borders */
  border-top: 1px solid var(--matterColorNegative);
  box-shadow: var(--boxShadowPopup);
  border-radius: 4px;
  transition: var(--transitionStyleButton);
}

.isOpenAsPopup {
  display: block;
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.plain {
  width: 100%;
}

.isOpen {
  display: block;
}

.contentWrapper {
  /* flex-wrap: wrap; */
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 0px;
}
@media (--viewportMedium) {
  .contentWrapper {
    padding-top: 0px;
    padding-bottom: 8px;
    margin-top: 0px;
    display: flex;
    align-items: flex-start;
  }
}

.label {
  @apply --marketplaceH5FontStyles;
  font-size: 18px;
  letter-spacing: -0.2px;
  font-weight: var(--fontWeightRegular);
  padding: 11px 0 13px 0;
  margin: 0 16px 0 0;
  display: flex;
  flex-direction: column;
}
@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.subLabel {
  font-size: 12px;
  letter-spacing: -0.2px;
}

.inputsWrapper {
  display: flex;
  padding-top: 6px;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.inputsItem {
  position: relative;
  display: flex;
  align-items: center;
}

.inputsCurrencySymbol {
  position: absolute;
  left: 0;
  bottom: 6px;
  font-weight: var(--fontWeightBold);
  
  @media (--viewportMedium) {
    bottom: 4px;
  }
}

.minPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  font-weight: var(--fontWeightBold);
  width: 62px;
  text-align: right;
  border-bottom-color: var(--customGold);
  border-bottom-width: 3px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  padding: 1px 4px 4px 12px;
}

.maxPrice {
  @apply --marketplaceSearchFilterSublabelFontStyles;
  font-weight: var(--fontWeightBold);
  width: 62px;
  text-align: right;
  border-bottom-color: var(--customGold);
  border-bottom-width: 3px;

  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  padding: 1px 4px 4px 12px;
}

.priceSeparator {
  margin: 4px 8px 0 8px;
}

@media (--viewportMedium) {
  .priceSeparator {
    margin: 2px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
}
@media (--viewportMedium) {
  .sliderWrapper {
    padding: 16px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
}

.clearButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0 auto 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.cancelButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--matterColorAnti);

  /* Layout */
  margin: 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--matterColor);
    transition: width var(--transitionStyleButton);
  }
}

.submitButton {
  @apply --marketplaceH4FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--marketplaceColor);

  /* Layout */
  margin: 0 0 0 19px;
  padding: 0;

  /* Override button styles */
  outline: none;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
    transition: width var(--transitionStyleButton);
  }
}
