@import '../../styles/propertySets.css';

.root {
  position: relative;
  box-shadow: var(--boxShadowBottomForm);

  @media (--viewportLarge) {
    box-shadow: none;

    /* Clearfix */
    width: 100%;
    overflow: hidden;
  }
}

.textarea {
  @apply --marketplaceH4FontStyles;
  border-bottom-width: 0;

  /* Avoid text going behind the submit button */
  padding: 22px 52px 25px 0;

  margin: 0;

  /* We need to remove horizontal paddings,
   since textarea uses content-box (autosize library fix). */
  width: 100%;

  border-bottom-width: 3px;
  border-bottom-color: var(--attentionColor);
  background-color: transparent;

  @media (--viewportMedium) {
    margin: 0;
    width: calc(100% - 52px);
  }
  @media (--viewportLarge) {
    padding: 0 0 5px 0;
    margin: 0;
    width: 100%;
    font-size: 20px;
    line-height: 32px;
    color: var(--matterColorAnti);
  }
}

.spinner {
  stroke: var(--matterColorLight);
  width: 18px;
  height: 18px;
  stroke-width: 4px;
}

.fillSuccess {
  fill: var(--successColor);
}

.strokeMatter {
  stroke: var(--matterColor);
}

.errorContainer {
  display: block;
  flex: 1;
  padding: 26px 0 0;
}

.error {
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);
  color: var(--failColor);
  margin: 0;
}

.submitButton {
  @apply --marketplaceButtonStylesSecondary;
  @apply --marketplaceH5FontStyles;
  font-weight: var(--fontWeightMedium);

  padding: 0 11px;
  min-height: auto;
  height: 41px;
  font-size: 14px;
  line-height: 24px;
  margin: 40px 0 0 0;
  width: auto;
  border: 1px solid var(--matterColorAnti);
  border-radius: 2px;
  

}

.uploadButton {
  margin-right: 11px;
}

.sendIcon {
  margin: -3px 5px 0 0;
}

.submitContainer {
  @media (--viewportMedium) {
    display: flex;
    flex-direction: row;
  }
}

.filesContainer {
  margin-bottom: 24px;

  @media (--viewportMedium) {
    flex: 1 1;
  }
}

.submitButtons {
  display: flex;
}

.selectedFiles {
  max-width: 240px;
  width: 100%;
}

.selectedFile {
  font-size: 12px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.111111px;
  color: var(--matterColor);
  margin-top: 4px;
  width: 100%;
  display: flex;
  align-items: center;

  & .selectedFileRemove {
    display: none;
  }

  &:hover {
    color: var(--marketplaceColor);
  }

  &:hover .selectedFileRemove {
    display: inline-block;
    margin-left: 4px;
  }

  &:hover path {
    fill: var(--marketplaceColor);
  }
}

.selectedFileName {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1;
}

.selectedFileRemove {
  flex-basis: auto;
}

